const Footer = ({ currentYear }) => {
    return (
        <footer className="bg-gray-700 text-white py-10 text-center w-full">
            <p className="text-white text-sm">
                Hecho con{" "}
                <span role="img" aria-label="heart">
                    💚
                </span>{" "}
                por{" "}
                <a
                    href="https://github.com/erasmoh"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                >
                    Erasmo Hernández
                </a>
            </p>
            <p className="mt-8 text-xs">
                Actualizado <b>Febrero-{currentYear}</b> Feriados de Chile.
            </p>
        </footer>
    );
}

export default Footer;