'use client'

import React, { useEffect, useState } from "react";
import axios from "axios";
import DateCard from "@/components/DateCard/DateCard";
import FilterButton from "@/components/FilterButton";
import DateCardSkeleton from "@/components/skeletons/DateCardSkeleton";

import ReactGA from "react-ga4";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";


function App() {
  const [dates, setDates] = useState([]);
  const [filteredDates, setFilteredDates] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("all"); // Default filter
  const [upcomingOnly, setUpcomingOnly] = useState(false); // Nuevo estado
  
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const filterDates = () => {
    let filtered = [...dates];

    if (currentFilter !== "all") {
      filtered = filtered.filter(
        (date) => date.irrenunciable === currentFilter
      );
    }

    if (upcomingOnly) {
      const now = new Date();
      filtered = filtered.filter((date) => new Date(date.fecha) > now);
    }

    setFilteredDates(filtered);
  };

  ReactGA.initialize("G-WX180M3FC2");

  useEffect(() => {
    axios.get("/api/feriados")
    .then(response => {
      setDates(response.data);
      setIsLoading(false);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });

    ReactGA.send({ hitType: "pageview" });
  }, [year]);
  
  useEffect(() => {
    filterDates();
  }, [dates, currentFilter, upcomingOnly]);

  const findNextHoliday = (dates) => {
    const now = new Date();
    const upcomingHolidays = dates
      .map((d) => new Date(d.fecha))
      .filter((date) => date > now);

    if (upcomingHolidays.length === 0) return null;

    return new Date(Math.min.apply(null, upcomingHolidays));
  };

  const [timeUntilNextHoliday, setTimeUntilNextHoliday] = useState(null);

  useEffect(() => {
    const nextHoliday = findNextHoliday(dates);

    const updateCounter = () => {
      const now = new Date();
      const timeDifference = nextHoliday - now;

      // Calculate all units at once to avoid multiple calculations
      const totalSeconds = Math.floor(timeDifference / 1000);
      const days = Math.floor(totalSeconds / (60 * 60 * 24));
      const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
      const seconds = totalSeconds % 60;

      setTimeUntilNextHoliday({ days, hours, minutes, seconds });
    };

    if (nextHoliday) {
      // Initial update
      updateCounter();
      // Update every second
      const interval = setInterval(updateCounter, 1000);

      return () => clearInterval(interval);
    }
  }, [dates]);

  const handleFilterButtonClick = (label) => {
    // Send a custom event to Google Analytics
    ReactGA.event({
      category: "click events",
      action: "filter click",
      label: label,
    });

    // Handle the state changes based on the clicked filter button
    if (label === "Todos") {
      setCurrentFilter("all");
    } else if (label === "Irrenunciable") {
      setCurrentFilter("1");
    } else if (label === "No Irrenunciable") {
      setCurrentFilter("0");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col align-start items-center w-full">
      {/* Header */}
      <Header year={year} />
      {/* Banner */}
      <div className="bg-red-700 p-4 w-full">
        <div className="text-sm font-semibold text-center">
          {isLoading || !timeUntilNextHoliday ? (
            <div className="animate-pulse">
              <div className="h-4 bg-red-500 rounded md:w-1/4 w-2/3 mx-auto"></div>
            </div>
          ) : (
            <div className="text-white">
              <span>Faltan: </span>
              <span>
                <b>{timeUntilNextHoliday.days}</b> días,{" "}
              </span>
              <span>
                <b>{timeUntilNextHoliday.hours}</b> horas,{" "}
              </span>
              <span>
                <b>{timeUntilNextHoliday.minutes}</b> minutos,{" "}
              </span>
              <span>
                <b>{timeUntilNextHoliday.seconds}</b> segundos para el próximo
                feriado.
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Filters */}
      <div className="container mx-auto mt-2 p-4 flex md:flex-row sm:flex-col justify-center items-center md:space-x-4">
        <p className="md:text-md sm:text-sm md:font-semibold text-gray-600">
          Filtrar por:
        </p>
        <div className="flex mx-0">
          <FilterButton
            isActive={currentFilter === "all"}
            onClick={() => handleFilterButtonClick("Todos")}
            label="Todos"
          />
          <FilterButton
            isActive={currentFilter === "1"}
            onClick={() => handleFilterButtonClick("Irrenunciable")}
            label="Irrenunciable"
          />
          <FilterButton
            isActive={currentFilter === "0"}
            onClick={() => handleFilterButtonClick("No Irrenunciable")}
            label="No Irrenunciable"
          />
          <FilterButton
            isActive={upcomingOnly}
            onClick={() => {
              handleFilterButtonClick(
                !upcomingOnly ? "Ocultar pasados" : "Ver Todos"
              );
              setUpcomingOnly(!upcomingOnly);
            }}
            label={!upcomingOnly ? "Ocultar pasados" : "Ver Todos"}
          />
        </div>
      </div>

      {/* Date List */}
      <div className="container mx-auto p-4 md:w-1/2 sm:w-full">
        {isLoading ? (
          [...Array(8)].map((_, index) => (
            <DateCardSkeleton key={index} />
          ))
        ) : (
          filteredDates.map((date, index) => (
            <DateCard date={date} key={index} />
          ))
        )}
      </div>
      <Footer currentYear={currentYear} />
    </div>
  );
}

export default App;
