const FilterButton = ({ isActive, onClick, label }) => {
  const activeClass = isActive
    ? "bg-blue-700 text-white"
    : "bg-white text-blue-700";

  return (
    <button
      className={`${activeClass} mx-1 px-2 py-2 rounded-lg border border-blue-700 text-xs`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default FilterButton;
