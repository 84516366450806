const Header = ({ year }) => {
    return (
        <header className="bg-blue-800 text-white p-4 w-full">
            <p className="text-4xl font-bold text-center">
                Feriados de Chile {year} 🇨🇱
            </p>
        </header>
    );
}

export default Header;