const DateCardSkeleton = () => {
  return (
    <div className="mb-4 p-4 bg-white shadow-md rounded-md flex flex-col sm:flex-row sm:items-center">
      <div className="w-full flex sm:flex-row">
        {/* Date section */}
        <div className="w-1/3 sm:w-full md:w-1/4 flex flex-col items-center border-r-2 border-gray-100">
          <div className="w-20 h-4 bg-gray-200 animate-pulse rounded mb-2" />
          <div className="w-12 h-8 bg-gray-200 animate-pulse rounded mb-2" />
          <div className="w-16 h-6 bg-gray-200 animate-pulse rounded mb-2" />
        </div>

        {/* Content section */}
        <div className="w-2/3 sm:w-full md:w-2/4 pl-4 sm:mt-0 flex flex-col">
          <div className="w-3/4 h-6 bg-gray-200 animate-pulse rounded mb-2" />
          <div className="w-1/2 h-4 bg-gray-200 animate-pulse rounded mb-2" />
          <div className="w-1/3 h-4 bg-gray-200 animate-pulse rounded mb-2" />
          <div className="w-2/3 h-3 bg-gray-200 animate-pulse rounded mt-2" />
        </div>
      </div>

      {/* Buttons section */}
      <div className="sm:w-[50px] flex flex-col items-center mt-4 sm:mt-0">
        <div className="w-full h-8 bg-gray-200 animate-pulse rounded mb-2" />
        <div className="w-full h-8 bg-gray-200 animate-pulse rounded" />
      </div>
    </div>
  );
};

export default DateCardSkeleton;