import "./DateCard.css";

const DateCard = ({ date, index }) => {
  const getWeekdayInSpanish = (dateString) => {
    const daysOfWeek = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const date = new Date(dateString);
    const dayOfWeek = date.getUTCDay();
    return daysOfWeek[dayOfWeek];
  };

  const getMonthNameInSpanish = (dateString) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const date = new Date(dateString);
    const monthIndex = date.getUTCMonth();
    return months[monthIndex];
  };

  const daysUntilDate = (targetDate) => {
    const now = new Date();
    const then = new Date(targetDate);
    const timeDifference = then - now;
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };

  const isFiestasPatrias = (dateString) => {
    const date = new Date(dateString);
    return (
      date.getUTCMonth() === 8 &&
      (date.getUTCDate() === 18 ||
        date.getUTCDate() === 19 ||
        date.getUTCDate() === 20)
    );
  };

  const shareDate = (date) => {
    const text = `Faltan ${daysUntilDate(date.fecha)} días para el Feriado ${
      date.nombre
    } el ${date.fecha}`;
    const url = `https://feriadosdechile.com/`;
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(url);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`;

    return whatsappUrl;
  };

  const copyDate = (dateContent) => {
    const text = `Faltan ${daysUntilDate(
      dateContent.fecha
    )} días para el Feriado ${dateContent.nombre} el ${dateContent.fecha}`;
    const url = `https://feriadosdechile.com/`;
    const whatsappUrl = `${text} 🇨🇱 | Míralos en ${url}`;

    navigator.clipboard.writeText(whatsappUrl);
  };

  const handleCopyClick = (e, date) => {
    e.preventDefault();
    copyDate(date);
  };

  const handleShareClick = (e, date) => {
    e.preventDefault();
    window.open(shareDate(date), "_blank");
  };

  return (
    <div
      key={index}
      className={`mb-4 p-4 bg-white shadow-md rounded-md flex flex-col sm:flex-row sm:items-center ${
        isFiestasPatrias(date.fecha) ? "fiestasPatrias-border" : ""
      }`}
    >
      <div className="w-full flex sm:flex-row ">
        <div className="w-1/3 sm:w-full md:w-1/4 flex flex-col items-center border-r-2 border-gray-100">
          <div className="text-gray-600">{getWeekdayInSpanish(date.fecha)}</div>
          <div className="text-4xl text-gray-900 font-bold">
            {new Date(date.fecha).getUTCDate()}
          </div>
          <div className="text-lg text-gray-900 font-semibold mb-2">
            {getMonthNameInSpanish(date.fecha)}
          </div>
        </div>
        <div className="w-2/3 sm:w-full md:w-2/4 pl-4 sm:mt-0 flex flex-col">
          <p className="text-gray-600 text-lg font-bold">{date.nombre}</p>
          {date.irrenunciable === "1" && (
            <p className="text-red-600 font-semibold">Irrenunciable</p>
          )}
          <span className="text-gray-600">{date.tipo}</span>

          <p className="text-gray-600 text-xs mt-2">
            {daysUntilDate(date.fecha) > 0 ? (
              <>Faltan {daysUntilDate(date.fecha)} días para este feriado</>
            ) : (
              <>Este feriado ya pasó</>
            )}
          </p>
        </div>
      </div>
      {daysUntilDate(date.fecha) > 0 && (
        <div className="flex flex-col items-center mt-4 sm:mt-0">
          <button
            onClick={(e) => handleCopyClick(e, date)}
            aria-label="Copiar descripción del feriado"
            className="md:mt-1 text-xs hover:text-blue-500 hover:bg-transparent bg-blue-500 text-white border hover:border-blue-500 border-transparent transition duration-300 ease-in-out flex items-center justify-center w-full py-2 px-4 rounded mb-2 sm:mb-0"
          >
            {/* <FaShare /> */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 458.624 458.624"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M339.588,314.529c-14.215,0-27.456,4.133-38.621,11.239l-112.682-78.67c1.809-6.315,2.798-12.976,2.798-19.871
			c0-6.896-0.989-13.557-2.798-19.871l109.64-76.547c11.764,8.356,26.133,13.286,41.662,13.286c39.79,0,72.047-32.257,72.047-72.047
			C411.634,32.258,379.378,0,339.588,0c-39.79,0-72.047,32.257-72.047,72.047c0,5.255,0.578,10.373,1.646,15.308l-112.424,78.491
			c-10.974-6.759-23.892-10.666-37.727-10.666c-39.79,0-72.047,32.257-72.047,72.047s32.256,72.047,72.047,72.047
			c13.834,0,26.753-3.907,37.727-10.666l113.292,79.097c-1.629,6.017-2.514,12.34-2.514,18.872c0,39.79,32.257,72.047,72.047,72.047
			c39.79,0,72.047-32.257,72.047-72.047C411.635,346.787,379.378,314.529,339.588,314.529z"
              />
            </svg>
          </button>
          <button
            onClick={(e) => handleShareClick(e, date)}
            aria-label="Compartir en whatsapp"
            className="md:mt-1 text-xs hover:text-green-500 bg-green-500 hover:bg-transparent text-white border hover:border-green-500 border-transparent transition duration-300 ease-in-out flex items-center justify-center w-full py-2 px-4 rounded"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default DateCard;
